<template>
    <div class="card_body">
        <div class="card_body__row card_body__row--billing">
            <div class="card_body__col card_body__col--sm">
                <!-- DATE -->
                <div class="card_body__detail">
                    <div class="card_body__detail-title">
                        Date
                    </div>
                    <div class="card_body__detail-counter">
                        <Clock />
                        <span>{{ billing.created_at | moment_bidding_text }}</span>
                    </div>
                </div>

                <!-- BATCH ID -->
                <div class="card_body__detail">
                    <div class="card_body__detail-title">
                        Batch ID
                    </div>
                    <div class="card_body__detail-counter">
                        <Clipboard />
                        <span>{{ billing.batchid }}</span>
                    </div>
                </div>

                <!-- Store Credit -->
                <div
                    v-if="billing.store_credit > 0"
                    class="card_body__detail"
                >
                    <div class="card_body__detail-title">
                        Used store credit
                    </div>
                    <div class="card_body__detail-counter">
                        <CreditCard />
                        <span>{{ billing.store_credit | money }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div
            v-if="billing.orders.length > 0"
            class="billing_order_list"
        >
            <div
                v-for="(order, orderIndex) in billing.orders"
                :key="orderIndex"
                class="billing_order"
            >
                <div class="billing_order_index">
                    {{ orderIndex + 1 }}.
                </div>
                <router-link
                    v-if="order.orderid"
                    :to="{
                        name: 'order_details',
                        params: {
                            id: order.orderid
                        }
                    }"
                    class="billing_order_orderid"
                >
                    {{ order.orderid }}
                </router-link>
                <div class="billing_order_title">
                    «{{ order.title }}»
                </div>
                <div class="billing_order_price">
                    {{ order.total | money }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import filtersMixin from '@/mixins/filters-mixin.js'
import Clock from '../../../icons/Сlock'
import Clipboard from '../../../icons/Сlipboard'
import CreditCard from '../../../icons/Credit-card'

export default {
    components: {
        CreditCard, Clipboard, Clock
    },
    mixins: [filtersMixin],
    props: {
        billing: {
            type: Object,
            required: true
        }
    }
}
</script>
