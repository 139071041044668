<template>
    <card>
        <billing-card-header
            :slot="'header'"
            :mode="mode"
            :batchid="data.batchid"
            :title="`${data.orders.length} orders in  ${data.batchid}`"
            :index="index"
            :status="data.status"
            :created_at="data.created_at"
            :total="data.total"
            :paymethod="data.paymethod"
        />
        <billing-card-body
            :slot="'content'"
            :billing="data"
        />
    </card>
</template>

<script>
// import Api from '@/helpers/api/index.js';

import Card from '@/components/account/cards/Card.vue'
import BillingCardHeader from '@/components/account/cards/billing-card/BillingCardHeader.vue'
import BillingCardBody from '@/components/account/cards/billing-card/BillingCardBody.vue'

export default {
    components: {
        Card,
        BillingCardHeader,
        BillingCardBody
    },
    props: {
        data: {
            type: Object,
            reqiured: true
        },
        index: {
            type: Number,
            required: true
        },
        mode: {
            type: String,
            default: 'list'
        }
    },
    methods: {

    }
}
</script>
